import { createContext, useContext, useMemo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/browser'
import { ICustomerContext } from 'interfaces/customer'
import * as backendEvent from 'lib/api/backend-event'
import { getCookieValue } from '@/lib/util/cookie'
import { ga4TagId } from 'constants/pixels'

// Temporary mapping - if all works well with Rudder stack we'll adopt those standard names.
export const ADD_TO_CART_EVENT_NAME = 'AddToCart'
export const RUDDERSTACK_ADD_TO_CART_EVENT_NAME = 'Product Added'
export const RUDDERSTACK_PRODUCT_VIEWED_EVENT_NAME = 'Product Viewed'
export const PAGE_VIEW_EVENT_NAME = 'PageView'

// Custom Events
export const STORE_LOCATOR_SELECT_SEARCH_RESULT_EVENT_NAME = 'Store locator - Select - Search result'
export const STORE_LOCATOR_SELECT_CLICK_GET_DIRECTIONS_EVENT_NAME = 'Store locator - Click - Get directions'

// Purchase Related Events
export const PURCHASE_EVENT_NAME = 'Purchase'
export const FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME = 'Flow - Viewed - Paid Order Confirmation'

const FB_PIXEL_STANDARD_EVENTS = [
    'AddPaymentInfo',
    ADD_TO_CART_EVENT_NAME,
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    PAGE_VIEW_EVENT_NAME,
    PURCHASE_EVENT_NAME,
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
]

export interface IProps {
    children: React.ReactNode
}

// Meta Pixel line items format
const CustomerContext = createContext(null)

const handleRouteChange = (url) => {
    const _fbp = getCookieValue('_fbp=')
    const _fbc = getCookieValue('_fbc=')

    if (window.rudderanalytics) {
        const properties = {
            page: url,
            $is_session_activity: true, // Required for Klaviyo to mark this as "Active on Site"
        }
        window.rudderanalytics.track('View page', properties)
    }

    // Send an event to the backend
    backendEvent.track(PAGE_VIEW_EVENT_NAME, {
        pathname: url,
        origin: window.location.origin,
        hash: window.location.hash,
        search: window.location.search,
        referrer: document.referrer,
        ...(_fbp && { _fbp }),
        ...(_fbc && { _fbc }),
    })
}

/**
 * Use this context provider to track customer behavior.
 * You can think of it as "Analytics" --> GA/Facebook Pixel
 * but a bit more generic so it also works for operational data;
 * for example, sending cart data into our email provider to send abandoned carts
 */
const CABINET_USER_ID_KEY = 'cabinet_user_id'

const getOrCreateUserId = (): number => {
    if (typeof window === 'undefined') return 0

    let userId = localStorage.getItem(CABINET_USER_ID_KEY)
    if (!userId) {
        // Generate a random 12-digit number
        userId = Math.floor(Math.random() * 900000000000 + 100000000000).toString()
        localStorage.setItem(CABINET_USER_ID_KEY, userId)
    }
    return parseInt(userId, 10)
}

const CustomerContextProvider = ({ children }: IProps): React.ReactElement => {
    const router = useRouter()
    const [identity, setIdentity] = useState(null)
    const [userId, setUserId] = useState<number>(0)

    useEffect(() => {
        setUserId(getOrCreateUserId())
    }, [])

    // Monitor inital pageviews / mounts and set up listener for hash changes
    useEffect(() => {
        const handleHashChange = () => {
            handleRouteChange(window.location.pathname)
        }

        handleRouteChange(window.location.pathname)

        window.addEventListener('hashchange', handleHashChange)

        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }
    }, [])

    // Monitor client side page transitions
    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router])

    const value = useMemo(() => {
        /**
         * Send event tracking data where ever we need it
         *
         * Currently sends events to the following platforms
         *   - Facebook Pixel
         *   - RudderStack
         */
        const track: ICustomerContext['track'] = (eventName, eventProperties) => {
            // Facebook
            if (window && window?.fbq) {
                const trackMethod = FB_PIXEL_STANDARD_EVENTS.includes(eventName) ? 'track' : 'trackCustom'
                switch (eventName) {
                    case FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME:
                        // Convert this to a "Purchase" conversion eventName for Meta
                        window.fbq(trackMethod, PURCHASE_EVENT_NAME, {
                            ...eventProperties,
                            // mapping this value to eventID for FB de-duplication
                            eventID: eventProperties.order_id,
                        })
                        break
                    // All other events
                    default:
                        window.fbq(trackMethod, eventName, eventProperties)
                }
            }

            // Google (GA4 tag)
            if (window && window.gtag) {
                if (eventName === FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME) {
                    // Google Ads does not let you specify custom conversion event names in the API call
                    // (but it's mapped in their UI)
                    // See definitions here: https://ads.google.com/aw/conversions/detail?ocid=328741658
                    const gtagCustomPurchaseEventName = eventProperties.newCustomer
                        ? 'conversion_event_purchase_1' // Flow - Viewed - Paid Order Confirmation - New Customer
                        : 'conversion_event_purchase' // Flow - Viewed - Paid Order Confirmation - Returning Customer
                    window.gtag('event', gtagCustomPurchaseEventName, {
                        send_to: ga4TagId, // GA4 will send to Google Ads (AW), no need to also send to AW here.
                        value: eventProperties.value,
                        currency: 'USD',
                        transaction_id: eventProperties.order_id,
                    })
                }
            }

            // Rudderstack
            if (window && window?.rudderanalytics) {
                // Remapping select event names and formats to the standard expected by RudderStack
                // Eventually we'll be able to make these the standard and RudderStack will forward
                // them onto the respective trackers. Keeping the changes small for now while we test.
                // https://www.rudderstack.com/docs/event-spec/ecommerce-events-spec/
                if (eventName === ADD_TO_CART_EVENT_NAME) {
                    // Note - Rudder stack standard events only support one item per Add to Cart event
                    // This means we might send multiple ids in the "product_id" field if a bundle was added
                    window.rudderanalytics.track(RUDDERSTACK_ADD_TO_CART_EVENT_NAME, {
                        product_id: eventProperties.content_ids.toString(),
                        name: eventProperties.content_name,
                        price: eventProperties.value,
                        currency: eventProperties.currency,
                    })
                }
                // Send along all other events to RudderStack
                else {
                    window.rudderanalytics.track(eventName, eventProperties)
                }
            }
        }

        /**
         * Identify an anonymous customer/user
         *
         * Currently sends events to the following platforms
         *   - RudderStack
         *   - Sentry
         */
        const identify = (email: string) => {
            // Identify with backend
            backendEvent.identify(email)

            // Identify in Rudderstack
            if (window?.rudderanalytics) {
                try {
                    window.rudderanalytics.identify(email, { email })
                } catch {
                    // Likely an ad-blocker or some other issue loading the library
                    // This won't impact the user so nothing else to do
                }
            }

            // Identify with Sentry
            Sentry.setUser({ email })

            // Save identity in state
            setIdentity(email)
        }

        return {
            loading: false, // TODO: remove loading state from downstream hook consumers
            identity,
            userId,
            track,
            identify,
        }
    }, [setIdentity, identity])

    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ICustomerContext => {
    const customerContext = useContext(CustomerContext)

    if (typeof customerContext === 'undefined') {
        throw new Error('You cannot use CustomerContext outside CustomerContextProvider')
    }

    return customerContext
}

export default CustomerContextProvider
