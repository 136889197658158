/* eslint-disable react/display-name */
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IUiResources } from 'interfaces/ui-resource'
import { getContentfulTransparentBackgroundImageQuery } from 'lib/util/image'
import Link from 'next/link'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
import styles from './style.module.css'
import { VisualizerGridVideo } from './visualizer-grid-video'

const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
    uiResources: IUiResources
}

const lgBreakPoint = screens.lg
const contentfulImageBGQuery = getContentfulTransparentBackgroundImageQuery()

const titleRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--header-big">{children}</p>,
}
const cardDesriptionRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-sm leading-140%">{children}</p>,
}

export const VisualizerGridSection = ({ section, uiResources }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { visualsCollection, richTitle, media, backgroundColor } = section

    return (
        <section className="section section--bigger-padding flex justify-center" style={{ backgroundColor }}>
            <div className="section--container relative">
                {/* header */}
                {richTitle?.json && (
                    <h2>
                        <ContentfulRichText content={richTitle.json} renderNode={titleRenderNode} />
                    </h2>
                )}

                {/* visuals */}
                {visualsCollection?.items?.length > 0 && (
                    <div className="mt-9 lg:mt-15 grid lg:grid-cols-2 gap-17.5 md:gap-y-0 md:gap-x-28 lg:gap-x-40 xl:gap-x-52">
                        {visualsCollection.items.map((visual) => {
                            if (!visual) {
                                return null
                            }
                            const { sys, mobileImagesCollection, desktopImagesCollection, richDescription, cta } =
                                visual

                            const firstMobileMedia = mobileImagesCollection?.items?.[0]
                            const firstDesktopMedia = desktopImagesCollection?.items?.[0]

                            const secondMobileMedia = mobileImagesCollection?.items?.[1]
                            const secondDesktopMedia = desktopImagesCollection?.items?.[1]

                            return (
                                <div
                                    key={sys?.id}
                                    className="md:odd:col-start-2 md:even:col-start-1 md:odd:mt-52 md:odd:first:mt-0 md:even:mt-[-100%] lg:even:mt-[-75%]"
                                >
                                    <div className="">
                                        {/* card cover image */}
                                        {(firstMobileMedia || firstDesktopMedia) && (
                                            <div
                                                className={`rounded-2lg overflow-hidden ${styles['aspect-ratio-container']}`}
                                            >
                                                <>
                                                    {firstMobileMedia?.contentType?.includes('video') ||
                                                    firstDesktopMedia?.contentType?.includes('video') ? (
                                                        <VisualizerGridVideo
                                                            video={firstMobileMedia || firstDesktopMedia}
                                                            className={styles['aspect-ratio-element']}
                                                            playButtonLabel={
                                                                uiResources?.['playMediaLabel']?.value as string
                                                            }
                                                            pauseButtonLabel={
                                                                uiResources?.['pauseMediaLabel']?.value as string
                                                            }
                                                        />
                                                    ) : (
                                                        <picture>
                                                            {firstDesktopMedia && (
                                                                <>
                                                                    {/* desktop webp */}
                                                                    <source
                                                                        media={`(min-width: ${lgBreakPoint})`}
                                                                        type="image/webp"
                                                                        srcSet={`
                                                ${firstDesktopMedia.url}?fm=webp&w=420 420w,
                                                ${firstDesktopMedia.url}?fm=webp&w=640 640w,
                                                ${firstDesktopMedia.url}?fm=webp&w=840 840w
                                            `}
                                                                        sizes="40vw"
                                                                    />
                                                                    {/* desktop jpeg */}
                                                                    <source
                                                                        media={`(min-width: ${lgBreakPoint})`}
                                                                        type="image/jpeg"
                                                                        srcSet={`
                                                ${firstDesktopMedia.url}?fm=jpg&w=420 420w,
                                                ${firstDesktopMedia.url}?fm=jpg&w=640 640w,
                                                ${firstDesktopMedia.url}?fm=jpg&w=840 840w
                                            `}
                                                                        sizes="40vw"
                                                                    />
                                                                </>
                                                            )}
                                                            {firstMobileMedia && (
                                                                <>
                                                                    {/* mobile webp */}
                                                                    <source
                                                                        type="image/webp"
                                                                        srcSet={`
                                                ${firstMobileMedia.url}?fm=webp&w=360 360w
                                                ${firstMobileMedia.url}?fm=webp&w=420 420w,
                                                ${firstMobileMedia.url}?fm=webp&w=640 640w
                                            `}
                                                                    />
                                                                    {/* mobile jpeg */}
                                                                    <source
                                                                        type="image/jpeg"
                                                                        srcSet={`
                                                ${firstMobileMedia.url}?fm=jpg&w=360 360w
                                                ${firstMobileMedia.url}?fm=jpg&w=420 420w,
                                                ${firstMobileMedia.url}?fm=jpg&w=640 640w
                                            `}
                                                                    />
                                                                </>
                                                            )}
                                                            {(firstMobileMedia?.url || firstDesktopMedia?.url) && (
                                                                <img
                                                                    src={
                                                                        (firstMobileMedia?.url ||
                                                                            firstDesktopMedia.url) + '?fm=jpg&w=640'
                                                                    }
                                                                    alt={
                                                                        firstMobileMedia?.title ||
                                                                        firstDesktopMedia?.title ||
                                                                        firstMobileMedia?.description ||
                                                                        firstDesktopMedia?.description
                                                                    }
                                                                    height={
                                                                        firstMobileMedia?.height ||
                                                                        firstDesktopMedia?.height
                                                                    }
                                                                    width={
                                                                        firstMobileMedia?.width ||
                                                                        firstDesktopMedia?.width
                                                                    }
                                                                    className={`h-full ${styles['aspect-ratio-element']}`}
                                                                    loading="lazy"
                                                                />
                                                            )}
                                                        </picture>
                                                    )}
                                                </>
                                            </div>
                                        )}

                                        {/* small image */}
                                        {(secondMobileMedia || secondDesktopMedia) && (
                                            <picture>
                                                {secondDesktopMedia && (
                                                    <>
                                                        {/* desktop svg */}
                                                        {secondDesktopMedia.url?.endsWith('.svg') && (
                                                            <source
                                                                media={`(min-width: ${lgBreakPoint})`}
                                                                type="image/svg+xml"
                                                                srcSet={secondDesktopMedia.url}
                                                            />
                                                        )}
                                                        {/* desktop webp */}
                                                        <source
                                                            media={`(min-width: ${lgBreakPoint})`}
                                                            type="image/webp"
                                                            srcSet={`
                                                ${secondDesktopMedia.url}?fm=webp&w=320 320w
                                            `}
                                                            sizes="40vw"
                                                        />
                                                        {/* desktop jpeg */}
                                                        <source
                                                            media={`(min-width: ${lgBreakPoint})`}
                                                            type="image/jpeg"
                                                            srcSet={`
                                                ${secondDesktopMedia.url}?fm=jpg&${contentfulImageBGQuery}&w=320 320w
                                            `}
                                                        />
                                                    </>
                                                )}
                                                {secondMobileMedia && (
                                                    <>
                                                        {/* mobile svg */}
                                                        {secondMobileMedia.url?.endsWith('.svg') && (
                                                            <source
                                                                type="image/svg+xml"
                                                                srcSet={secondMobileMedia.url}
                                                            />
                                                        )}
                                                        {/* mobile webp */}
                                                        <source
                                                            type="image/webp"
                                                            srcSet={`
                                                ${secondMobileMedia.url}?fm=webp&w=320 320w
                                            `}
                                                        />
                                                        {/* mobile jpeg */}
                                                        <source
                                                            type="image/jpeg"
                                                            srcSet={`
                                                ${secondMobileMedia.url}?fm=jpg&${contentfulImageBGQuery}&w=320 320w
                                            `}
                                                        />
                                                    </>
                                                )}

                                                <img
                                                    src={
                                                        (secondMobileMedia?.url || secondDesktopMedia.url) +
                                                        `?fm=jpg&w=320&${contentfulImageBGQuery}`
                                                    }
                                                    alt={
                                                        secondMobileMedia?.title ||
                                                        secondDesktopMedia?.title ||
                                                        secondMobileMedia?.description ||
                                                        secondDesktopMedia?.description
                                                    }
                                                    height={secondMobileMedia?.height || secondDesktopMedia?.height}
                                                    width={secondMobileMedia?.width || secondDesktopMedia?.width}
                                                    className="mt-7.5"
                                                    loading="lazy"
                                                />
                                            </picture>
                                        )}

                                        {/* description */}
                                        {richDescription?.json && (
                                            <div className="mt-5">
                                                <ContentfulRichText
                                                    content={richDescription?.json}
                                                    renderNode={cardDesriptionRenderNode}
                                                />
                                            </div>
                                        )}

                                        {/* cta */}
                                        {cta?.href && (
                                            <Link
                                                href={cta.href}
                                                className="mt-5 pb-5 text-gray text-xsm tracking-wide underline inline-block"
                                            >
                                                {cta.name}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                {/* section footer image */}
                {media && (
                    <picture>
                        {/* svg */}
                        {media.url?.endsWith('.svg') && <source type="image/svg+xml" srcSet={media.url} />}
                        {/* webp */}
                        <source type="image/webp" srcSet={media.url + '?fm=webp&w=300'} />
                        {/* jpeg */}
                        <source type="image/jpeg" srcSet={media.url + `?fm=jpg&w=300&${contentfulImageBGQuery}`} />

                        <img
                            src={media.url + '?fm=jpg&w=300'}
                            alt={media.title || media.description}
                            height={media.height}
                            width={media.width}
                            className="h-36 lg:h-52 w-auto mt-10 lg:mt-28 mx-auto md:ml-auto md:mx-0 md:absolute bottom-0 right-0"
                            loading="lazy"
                        />
                    </picture>
                )}
            </div>
        </section>
    )
}
