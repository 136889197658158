import { contentfulClient } from '@/lib/contentful-client'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'
import RudderStack from 'components/analytics/rudderstack'
import { AuthContextProvider } from 'components/auth/auth'
import CartSidebar from 'components/cart/cart-sidebar'
import EmailCapturePopup from 'components/email-capture-popup'
import Meta from 'components/meta'
import { MobileNavSidebar } from 'components/navigation/mobile-nav-sidebar'
import Redirects from 'components/redirects'
import { ApiProvider } from 'context/api-context'
import BannerContextProvider from 'context/banner-context'
import CartSidebarContextProvider from 'context/cart-sidebar-context'
import CustomerContextProvider from 'context/customer-context'
import EmailCapturePopupContextProvider from 'context/email-capture-popup-context'
import GrowthBookWrapperContextProvider from 'context/growthbook-context'
import { GrowthbookFeaturesContextProvider } from 'context/growthbook-features-context'
import { HeaderNavbarContextProvider } from 'context/header-navbar-context'
import { HelpSidebarContextProvider } from 'context/help-sidebar-context'
import { MobileNavSidebarContextProvider } from 'context/mobile-nav-sidebar-context'
import { PharmacyCardPopupContextProvider } from 'context/pharmacy-card-popup-context'
import { ProfileContextProvider } from 'context/profile-context'
import RXCartContextProvider from 'context/rx-cart-context'
import { ToastContextProvider } from 'context/toast-context'
import UserContextProvider from 'context/user-context'
import { IBanner } from 'interfaces/banner'
import { setItemInSS } from 'lib/util/storage'
import type { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import globalManifest from '../data/global-manifest.json'
import 'styles/index.css'
import 'react-tooltip/dist/react-tooltip.css'

const { resources } = globalManifest

const queryClient = new QueryClient()

const onRedirectCallback = (appState): void => {
    if (appState?.returnTo) Router.replace(appState?.returnTo)
}

function CabinetApp({ Component, pageProps }: AppProps & { banners: IBanner[] }): JSX.Element {
    const router = useRouter()
    const { query } = router

    // Refresh the growthbook visual editor settings
    useEffect(() => {
        const onChange = () => window.growthbook?.refresh()
        router.events.on('routeChangeComplete', onChange)

        // Cleanup on dismount
        return () => router.events.off('routeChangeComplete', onChange)
    }, [router.events])

    useEffect(() => {
        if (!query) {
            return
        }

        try {
            // add utm query params in session storage
            for (const key in query) {
                if (key.startsWith('utm_')) {
                    setItemInSS(key, query[key] as string)
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [query])

    return (
        <>
            <Redirects />
            <GrowthbookFeaturesContextProvider>
                <ToastContextProvider>
                    <AuthContextProvider
                        domain={process.env.AUTH0_PUBLIC_DOMAIN}
                        client_id={process.env.AUTH0_PUBLIC_CLIENT_ID}
                        audience={process.env.AUTH0_PUBLIC_AUDIENCE}
                        redirect_uri={typeof window !== 'undefined' && window.location.origin}
                        onRedirectCallback={onRedirectCallback}
                    >
                        <ApiProvider>
                            <ApolloProvider client={contentfulClient}>
                                <QueryClientProvider client={queryClient}>
                                    <RudderStack />
                                    <CustomerContextProvider>
                                        <GrowthBookWrapperContextProvider>
                                            <UserContextProvider>
                                                <ProfileContextProvider>
                                                    <MobileNavSidebarContextProvider>
                                                        <CartSidebarContextProvider>
                                                            <RXCartContextProvider>
                                                                <Meta uiResources={resources} />
                                                                <HelpSidebarContextProvider>
                                                                    <PharmacyCardPopupContextProvider>
                                                                        <div className="cabinet-container md:border-r md:border-l lg:border-r-0 lg:border-l-0 2xl:border-r 2xl:border-l border-gray box-content">
                                                                            <BannerContextProvider>
                                                                                <EmailCapturePopupContextProvider>
                                                                                    <HeaderNavbarContextProvider>
                                                                                        <Component {...pageProps} />
                                                                                    </HeaderNavbarContextProvider>
                                                                                    <EmailCapturePopup />
                                                                                </EmailCapturePopupContextProvider>
                                                                            </BannerContextProvider>
                                                                        </div>
                                                                        <MobileNavSidebar />
                                                                    </PharmacyCardPopupContextProvider>
                                                                </HelpSidebarContextProvider>
                                                                <CartSidebar />
                                                            </RXCartContextProvider>
                                                        </CartSidebarContextProvider>
                                                    </MobileNavSidebarContextProvider>
                                                </ProfileContextProvider>
                                            </UserContextProvider>
                                        </GrowthBookWrapperContextProvider>
                                    </CustomerContextProvider>
                                </QueryClientProvider>
                            </ApolloProvider>
                        </ApiProvider>
                    </AuthContextProvider>
                </ToastContextProvider>
            </GrowthbookFeaturesContextProvider>
        </>
    )
}

export default CabinetApp
