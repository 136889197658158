import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client/core/index'
import { onError } from '@apollo/client/link/error/index.js'
import { RetryLink } from '@apollo/client/link/retry/index.js'
import * as Sentry from '@sentry/nextjs'
import nodeFetch from 'node-fetch'

// operations to retry
//
const retryOperations = []

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    console.log(`Apollo error on ${operation.operationName}`)
    if (response?.errors) {
        response.errors.forEach((error) => {
            console.log(`[Apollo error]: ${error.message}`)
        })
    }
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message === 'Throttled') {
                return forward(operation)
            }
            const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation.operationName}`
            if (typeof window !== 'undefined') {
                Sentry.captureException(errorMsg)
            } else {
                console.error(errorMsg)
            }
        })
    if (networkError) {
        const errorMsg = `[Network error]: ${networkError}, Operation: ${operation.operationName}`
        if (typeof window !== 'undefined') {
            Sentry.captureException(errorMsg)
        } else {
            console.error(errorMsg)
        }
    }
})

const retryLink = new RetryLink({
    attempts: {
        max: 3,
        retryIf: (error, operation) => !!error && retryOperations.includes(operation.operationName),
    },
    delay: {
        initial: 300, // 300ms
        max: 8000, // wait for at most 8 seconds to retry
    },
})

export function createContentfulClient(): InstanceType<typeof ApolloClient> {
    const httpLink = new HttpLink({
        uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_BRANCH}`,
        headers: {
            Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
        },
        fetch: typeof window === 'undefined' ? nodeFetch : fetch,
    })

    return new ApolloClient({
        link: from([errorLink, retryLink, httpLink]),
        cache: new InMemoryCache(),
    })
}

const contentfulClient = createContentfulClient()
export { contentfulClient }
