import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { visualizerWidgetCardsInternalName } from 'constants/contentful'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IUiResources } from 'interfaces/ui-resource'
import Link from 'next/link'
import React from 'react'
import twConfig from 'tailwind-light-config'
import styles from './style.module.css'

const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
    uiResources?: IUiResources
    className?: string
}

const lgBreakPoint = screens.lg

export const VisualizerWidgetGridSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { visualsCollection, internalName } = section

    const isMultipleWidgets = visualsCollection?.items?.length > 1

    const descriptionRenderNode = {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <p
                className={`text-xl font-medium tracking-tight mt-2 ${
                    isMultipleWidgets ? 'leading-140% lg:leading-120%' : ''
                }`}
            >
                {children}
            </p>
        ),
    }

    const textColor = internalName === visualizerWidgetCardsInternalName ? 'text-white' : ''

    return (
        <section className={`${isMultipleWidgets ? 'section--v2--sm' : ''} ${textColor}`}>
            {/* Visuals */}
            {visualsCollection?.items?.length > 0 && (
                <div className={`grid grid-cols-1 gap-6 p-4 ${isMultipleWidgets ? 'md:grid-cols-2 md:p-6' : 'mt-4'}`}>
                    {visualsCollection.items.map((visual) => {
                        if (!visual) {
                            return null
                        }
                        const {
                            sys,
                            mobileImagesCollection,
                            desktopImagesCollection,
                            richDescription,
                            cta,
                            title,
                            description,
                            associatedColors,
                        } = visual

                        const firstMobileMedia = mobileImagesCollection?.items?.[0]
                        const firstDesktopMedia = desktopImagesCollection?.items?.[0]

                        return (
                            <div
                                key={sys?.id}
                                className="flex flex-col justify-between rounded-xl border border-gray-medium p-8"
                                style={{
                                    background: associatedColors,
                                }}
                            >
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 xl:sm:min-h-[300px]">
                                    <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden">
                                        <picture>
                                            {firstDesktopMedia && (
                                                <>
                                                    {/* desktop webp */}
                                                    <source
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        type="image/webp"
                                                        srcSet={`
                                                ${firstDesktopMedia.url}?fm=webp&w=420 420w,
                                                ${firstDesktopMedia.url}?fm=webp&w=640 640w,
                                                ${firstDesktopMedia.url}?fm=webp&w=840 840w
                                            `}
                                                        sizes="40vw"
                                                    />
                                                    {/* desktop jpeg */}
                                                    <source
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        type="image/jpeg"
                                                        srcSet={`
                                                ${firstDesktopMedia.url}?fm=jpg&w=420 420w,
                                                ${firstDesktopMedia.url}?fm=jpg&w=640 640w,
                                                ${firstDesktopMedia.url}?fm=jpg&w=840 840w
                                            `}
                                                        sizes="40vw"
                                                    />
                                                </>
                                            )}
                                            {firstMobileMedia && (
                                                <>
                                                    {/* mobile webp */}
                                                    <source
                                                        type="image/webp"
                                                        srcSet={`
                                                ${firstMobileMedia.url}?fm=webp&w=360 360w
                                                ${firstMobileMedia.url}?fm=webp&w=420 420w,
                                                ${firstMobileMedia.url}?fm=webp&w=640 640w
                                            `}
                                                    />
                                                    {/* mobile jpeg */}
                                                    <source
                                                        type="image/jpeg"
                                                        srcSet={`
                                                ${firstMobileMedia.url}?fm=jpg&w=360 360w
                                                ${firstMobileMedia.url}?fm=jpg&w=420 420w,
                                                ${firstMobileMedia.url}?fm=jpg&w=640 640w
                                            `}
                                                    />
                                                </>
                                            )}
                                            {(firstMobileMedia?.url || firstDesktopMedia?.url) && (
                                                <img
                                                    src={
                                                        (firstMobileMedia?.url || firstDesktopMedia?.url) +
                                                        '?fm=jpg&w=640'
                                                    }
                                                    alt={
                                                        firstMobileMedia?.title ||
                                                        firstDesktopMedia?.title ||
                                                        firstMobileMedia?.description ||
                                                        firstDesktopMedia?.description
                                                    }
                                                    height={firstMobileMedia?.height || firstDesktopMedia?.height}
                                                    width={firstMobileMedia?.width || firstDesktopMedia?.width}
                                                    className={`h-full ${styles['aspect-ratio-element']}`}
                                                    loading="lazy"
                                                />
                                            )}
                                        </picture>
                                    </div>

                                    <div className="flex flex-col sm:space-y-4 sm:col-span-2">
                                        <div>
                                            {/* Picture - Title */}
                                            <h3
                                                className={`${
                                                    isMultipleWidgets
                                                        ? 'text-xl lg:text-2xl font-semibold'
                                                        : 'text-xl font-semibold'
                                                }`}
                                            >
                                                {title}
                                            </h3>
                                            {/* Picture - Description */}
                                            <p
                                                className={`${
                                                    isMultipleWidgets
                                                        ? 'text-xl font-medium mt-2'
                                                        : 'text-xl font-medium'
                                                }`}
                                            >
                                                {description}
                                            </p>
                                        </div>
                                        <ContentfulRichText
                                            content={richDescription.json}
                                            renderNode={descriptionRenderNode}
                                        />
                                    </div>
                                </div>
                                {cta?.href && (
                                    <Link href={cta.href} className="mt-8 button button-primary section--primary-cta">
                                        {cta.name}
                                    </Link>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </section>
    )
}
