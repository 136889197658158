import { membershipPeriodOptions } from 'hooks/useMembershipProduct'
import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { ILineItemMembership } from 'interfaces/order/order'
import { PencilIcon } from 'icons/pencil-icon'
import { IconButton } from 'components/buttons/button'

export interface IProps {
    lineItem: ILineItemMembership
    onEdit?: () => void
}

const { resources: globalUIResources } = globalManifest

export function CartMembershipLineItem({ lineItem, onEdit }: IProps): React.ReactElement {
    const { product_name, total_price, product_image_url, interval } = lineItem

    const totalPriceNum = Number(total_price)

    return (
        <div data-cy="membership-line-item">
            <div className="flex flex-wrap xs:flex-nowrap justify-between items-start gap-3">
                {/* thumbnail */}
                <picture>
                    {/* svg */}
                    {product_image_url.endsWith('.svg') && (
                        <source type="image/svg+xml" srcSet={`${product_image_url}`} />
                    )}
                    {/* webp */}
                    <source type="image/webp" srcSet={`${product_image_url}?w=200&fit=thumb&fm=webp`} />
                    {/* jpeg */}
                    <source type="image/jpeg" srcSet={`${product_image_url}?w=200&fit=thumb&fm=jpg`} />
                    <img
                        src={`${product_image_url}?w=200&fit=thumb`}
                        alt=""
                        className="rounded shrink-0 w-[62px] h-[62px] object-cover"
                        loading="lazy"
                    />
                </picture>

                <div className="w-0 grow flex flex-col justify-between items-start">
                    <div className="w-full flex justify-between items-start">
                        <div className="w-0 grow">
                            <div className="text-xl block truncate leading-120% -tracking-wide">{product_name}</div>
                        </div>
                        {/* price */}
                        <div
                            className={`font-bold ml-auto leading-140% ${totalPriceNum === 0 ? '' : 'tracking-wider'}`}
                        >
                            {totalPriceNum === 0
                                ? globalUIResources['freeLabel'].value
                                : getFormattedNumberWithCurrency(totalPriceNum, undefined)}
                        </div>
                    </div>
                    <div className="flex items-center mt-2 gap-x-2">
                        <div
                            className={`p-1.5 rounded-lg text-xs font-bold leading-120% -tracking-7 bg-gray-medium`}
                            data-cy="membership-interval"
                        >
                            {interval === membershipPeriodOptions.YEARLY
                                ? globalUIResources['membershipLineItemBilledYearlyLabel'].value
                                : globalUIResources['membershipLineItemBilledMonthlyLabel'].value}
                        </div>
                        {typeof onEdit === 'function' && (
                            <IconButton
                                label="edit membership"
                                data-cy="edit-membership-button"
                                className="p-0"
                                onClick={onEdit}
                            >
                                <PencilIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
