import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import { BloatedButton } from 'components/buttons/button'
import Loader from 'components/loading/loader'
import { ILocalProduct, personalizedToken, refillableGlassBottle, travelTin } from 'constants/products'
import globalManifest from 'data/global-manifest.json'
import { PRODUCT_TYPE } from 'enum/product-type'
import { IFetchMembershipStatus } from 'hooks/useMembershipProduct'
import { IReturn as IUseOrderReturn } from 'hooks/useOrder'
import { ILineItemMembership } from 'interfaces/order/order'
import { IProductMembership } from 'interfaces/product/product'
import { CabinetSystem } from './cabinet-system'
import { CartLineItem } from './cart-line-item'
import { CartMembershipLineItem, IProps as IMembershipLineItemProps } from './cart-membership-line-item'
import { CartMembershipUpsell, IProps as ICartMembershipUpsellProps } from './cart-membership-upsell'
import { IPrescriptionLineItemProps } from './cart-prescription-line-item'

interface IProps {
    order: IUseOrderReturn
    onCheckout?: () => void
    isCartClearable?: boolean
    onRemove?: IPrescriptionLineItemProps['onRemove']
    hideCheckout?: boolean
    retry?: () => void
    isShowMultiplePackageDisclaimer?: boolean
    onEdit?: IMembershipLineItemProps['onEdit']
    isHideCabinetSystem?: boolean
    pricingSummaryContainerClassName?: string
    pricingSummaryWrapperClassName?: string
    membershipProduct?: IProductMembership
    fetchMembershipProduct?: () => void
    fetchMembershipProductStatus?: IFetchMembershipStatus
    onMembershipSelect?: ICartMembershipUpsellProps['onMembershipSelect']
    selectedMembership?: ICartMembershipUpsellProps['selectedMembership']
    showCompareLabel?: boolean
}

const { resources: globalUIResources } = globalManifest

export function CartSummary({
    order,
    onCheckout,
    isCartClearable,
    onRemove,
    hideCheckout,
    retry,
    isShowMultiplePackageDisclaimer,
    isHideCabinetSystem,
    pricingSummaryContainerClassName,
    pricingSummaryWrapperClassName,
    membershipProduct,
    fetchMembershipProduct,
    fetchMembershipProductStatus,
    onMembershipSelect,
    selectedMembership,
    showCompareLabel,
}: IProps) {
    const {
        line_items,
        totalPrice,
        price_tax,
        isFreeShipping,
        sustainability_discount,
        sustainability_fee,
        shippingPriceAfterDiscount,
        is_club_member,
        isOrderError,
        isLoading,
        subTotalPrice,
        shipping_price,
        membershipPrice,
        membershipSaving,
    } = order

    if (isOrderError) {
        return (
            <div className="flex text-sm justify-center h-full items-center">
                <p>There was an error processing your cart. Please try again.</p>

                <button onClick={retry} className="underline ml-1">
                    {globalUIResources['retryLabel'].value}
                </button>
            </div>
        )
    }

    const isShowMembershipUpsell = Boolean(membershipProduct)

    const membershipLineItem = line_items.find((lt) => lt.type === PRODUCT_TYPE.MEMBERSHIP) as ILineItemMembership
    const prescriptionLineItems = line_items.filter((lt) => lt.type === PRODUCT_TYPE.PRESCRIPTION)

    const cabinetSystemProducts: ILocalProduct[] = [
        {
            ...refillableGlassBottle,
            quantity: prescriptionLineItems.length,
        },
        {
            ...personalizedToken,
            quantity: prescriptionLineItems.length,
        },
        {
            ...travelTin,
            quantity: 1,
        },
    ]

    return (
        <div data-cy="cart-summary" className="flex flex-col flex-grow py-4">
            {isShowMembershipUpsell && (
                <div className="mb-4 p-4 pb-0">
                    <CartMembershipUpsell
                        membershipProduct={membershipProduct}
                        fetchMembershipProductStatus={fetchMembershipProductStatus}
                        fetchMembershipProduct={fetchMembershipProduct}
                        onMembershipSelect={onMembershipSelect}
                        selectedMembership={selectedMembership}
                    />
                </div>
            )}

            {isLoading ? (
                <div className="h-full flex-grow grid place-content-center">
                    <Loader secondary className="-mt-24" />
                </div>
            ) : (
                <>
                    <div className="p-4 pt-0">
                        <div>
                            {/* medicines */}
                            <ul className="flex flex-col flex-grow gap-y-1.5">
                                {!isShowMembershipUpsell && membershipLineItem && (
                                    <div className="mb-4">
                                        <CartMembershipLineItem lineItem={membershipLineItem} />
                                    </div>
                                )}
                                {prescriptionLineItems
                                    .filter((lt) => lt.type === PRODUCT_TYPE.PRESCRIPTION)
                                    .map((lt) => {
                                        return (
                                            <li key={'drug_id' in lt ? lt.drug_id : lt.product_id}>
                                                <CartLineItem
                                                    lineItem={lt}
                                                    isRemovable={isCartClearable}
                                                    onRemove={() => {
                                                        if (lt.type === PRODUCT_TYPE.PRESCRIPTION) {
                                                            onRemove(lt)
                                                        }
                                                    }}
                                                    showCompareLabel={showCompareLabel}
                                                />
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>

                        {!isHideCabinetSystem && <CabinetSystem products={cabinetSystemProducts} isShowProductsOnly />}
                    </div>

                    <div className={`mt-auto ${pricingSummaryContainerClassName}`}>
                        <div className={`border-t border-gray-medium mt-4 p-5 ${pricingSummaryWrapperClassName}`}>
                            {/* pricing summary */}
                            <div className="flex flex-col gap-1 text-sm leading-140% -tracking-10 [&>div]:flex [&>div]:gap-2 [&>div]:justify-between [&_span:nth-child(2)]:font-bold">
                                <div data-cy="subtotal">
                                    <span>{globalUIResources['shoppingCartSubtotal'].value}</span>
                                    <span data-cy="shipping-price">
                                        {getFormattedNumberWithCurrency(subTotalPrice ?? 0)}
                                    </span>
                                </div>

                                <div data-cy="membership-shipping">
                                    <span>{globalUIResources['shippingLabel'].value}</span>
                                    <div>
                                        {shippingPriceAfterDiscount < Number(shipping_price) && (
                                            <s className="text-gray-darker font-bold mr-2">
                                                {getFormattedNumberWithCurrency(Number(shipping_price))}
                                            </s>
                                        )}

                                        {isFreeShipping ? (
                                            <span data-cy="free-shipping">
                                                {is_club_member
                                                    ? globalUIResources['freeShippingWithMembership'].value
                                                    : globalUIResources['freeLabel'].value}
                                            </span>
                                        ) : (
                                            <span data-cy="shipping-price">
                                                {getFormattedNumberWithCurrency(shippingPriceAfterDiscount ?? 0)}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* membership savings */}
                                {membershipSaving > 0 && (
                                    <div>
                                        <span>{globalUIResources['cartMembershipDiscountLabel'].value}</span>
                                        <span data-cy="membership-savings" className="text-green font-bold">
                                            &ndash;{getFormattedNumberWithCurrency(membershipSaving)}
                                        </span>
                                    </div>
                                )}

                                {/* membership fee */}
                                {membershipPrice > 0 && (
                                    <div>
                                        <span>{globalUIResources['cartSummaryMembershipPriceLabel'].value}</span>
                                        <span data-cy="membership-fee" className="font-bold">
                                            {getFormattedNumberWithCurrency(membershipPrice)}
                                        </span>
                                    </div>
                                )}

                                {price_tax > 0 && (
                                    <div>
                                        <span>Tax</span>
                                        <span className="font-bold">{getFormattedNumberWithCurrency(price_tax)}</span>
                                    </div>
                                )}

                                <hr className="border-gray-medium" />

                                <div className="font-bold text-lg -tracking-[0.54px]">
                                    <span>{globalUIResources['totalLabel'].value}</span>
                                    <span data-cy="total">{getFormattedNumberWithCurrency(totalPrice ?? 0)}*</span>
                                </div>
                            </div>
                            <div className="mt-1 text-gray-darker text-xs leading-3 -tracking-6 flex flex-col gap-1">
                                {Number(sustainability_fee) > 0 && sustainability_fee !== sustainability_discount && (
                                    <div>*{globalUIResources['rxSustainabilityFeeDisclaimer']?.value}</div>
                                )}
                                {isShowMultiplePackageDisclaimer && (
                                    <div>*{globalUIResources['multiplePackageDeliveryDisclaimer'].value}</div>
                                )}
                            </div>

                            {!hideCheckout && (
                                <BloatedButton
                                    className="w-full mt-5"
                                    onClick={onCheckout}
                                    data-cy="checkout-button"
                                    disabled={line_items.length === 0}
                                >
                                    {globalUIResources['rxCartCheckoutButtonLabel'].value}
                                </BloatedButton>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
