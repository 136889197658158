import { useMemo, useState, createContext, useContext } from 'react'
import { useAuth } from 'components/auth/auth'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { useCustomerContext } from 'context/customer-context'
import { useGrowthbookFeaturesContext } from './growthbook-features-context'

export interface IProps {
    children: React.ReactNode
}

interface IGrowthBookWrapperContext {
    isFetchingFeatures: boolean
    isSettingGrowthbook: boolean
    loadingFailed: boolean
    isGrowthbookReady: boolean // provider has been updated with fetched features
}

const GrowthBookWrapperContext = createContext<IGrowthBookWrapperContext>(null)

export const GrowthBookWrapperContextProvider = ({ children }: IProps): React.ReactElement => {
    const customer = useCustomerContext()
    const { isAuthenticated } = useAuth()
    const [isSettingGrowthbook, setIsSettingGrowthbook] = useState(true)

    const { features, isFetchingFeatures, error } = useGrowthbookFeaturesContext()

    const growthbook = useMemo(() => {
        if (customer.loading) {
            return
        }
        setIsSettingGrowthbook(false)

        let customerEmailDomain

        if (customer.identity) {
            customerEmailDomain = customer.identity.includes('@') ? customer.identity.split('@')[1] : undefined
        }

        // Set the growthbook config in the window for the visual editor
        if (typeof window !== 'undefined' && window) {
            window.GROWTHBOOK_CONFIG = {
                anonId: customer.userId,
                userId: customer.identity,
                track: (experimentId, variationId) => {
                    customer.track('Experiment Viewed', {
                        experimentId,
                        variationId,
                    })
                },
            }
            // Refresh growthbook so experiment loads
            window?.growthbook?.refresh()
        }

        // Instantiate a Growthbook object for in-code targeting
        return new GrowthBook({
            // Loading in remote features
            features,
            // The attributes you want to use to assign variations
            // Using attributes from customer
            attributes: {
                anonId: customer.userId, // This is an id from tied to the device
                id: customer.identity, // This is a user's email, if available
                emailDomain: customerEmailDomain,
                employee: customerEmailDomain === 'wearecabinet.com',
                loggedIn: isAuthenticated,
            },
            // Called every time the user is put into an experiment
            trackingCallback: (experiment, result) => {
                customer.track('Experiment Viewed', {
                    experimentId: experiment.key,
                    variationId: result.variationId,
                    variationValue: result.value,
                })
            },
        })
    }, [customer, features])

    const value = useMemo(
        () => ({
            isFetchingFeatures,
            isSettingGrowthbook,
            loadingFailed: Boolean(error),
            isGrowthbookReady: isFetchingFeatures === false && !isSettingGrowthbook,
        }),
        [isFetchingFeatures, isSettingGrowthbook, error],
    )

    return (
        <GrowthBookWrapperContext.Provider value={value}>
            <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
        </GrowthBookWrapperContext.Provider>
    )
}

export const useGrowthBookWrapperContext = (): IGrowthBookWrapperContext => {
    const growthBookWrapperContext = useContext(GrowthBookWrapperContext)

    if (growthBookWrapperContext === undefined) {
        throw new Error('You cannot use growthBookWrapperContext out side of GrowthBookWrapperContextProvider')
    }

    return growthBookWrapperContext
}

export default GrowthBookWrapperContextProvider
