export const isString = (val: any) => {
    return typeof val === 'string' || val instanceof String
}

export const isNil = (val: any) => {
    return val === null || val === undefined
}

export const isPositiveInteger = (val: any) => {
    return Number.isInteger(val) && val > 0
}

/*
Filter an object to only the pickKey values.
Nested value retrieval is not supported
 */
export const pick = (val: object, pickKeys: string[]) => {
    return pickKeys.reduce((filteredVal: object, pickKey: string) => {
        filteredVal[pickKey] = val[pickKey]
        return filteredVal
    }, {})
}

/*
Filter an object for values that are not null or undefined
 */
export const getObjectRemoveNils = (val: object) => {
    return Object.entries(val).reduce((finalDict, [key, val]) => {
        if (!isNil(val)) {
            finalDict[key] = val
        }
        return finalDict
    }, {})
}

/*
 Compare two dictionaries (objects) for deep equality
 */
export const areDictionariesEqual = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
    // Check if both inputs are objects and not null
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return obj1 === obj2
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // Quick check: different number of keys means not equal
    if (keys1.length !== keys2.length) {
        return false
    }

    // Check each key and its corresponding value
    for (const key of keys1) {
        // Check if the key exists in the second object
        if (!obj2.hasOwnProperty(key)) {
            return false
        }

        const val1 = obj1[key]
        const val2 = obj2[key]

        // Recursive deep comparison for nested objects
        if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!areDictionariesEqual(val1, val2)) {
                return false
            }
        }
        // For arrays, do a deep comparison
        else if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1.length !== val2.length) {
                return false
            }

            for (let i = 0; i < val1.length; i++) {
                if (typeof val1[i] === 'object' && typeof val2[i] === 'object') {
                    if (!areDictionariesEqual(val1[i], val2[i])) {
                        return false
                    }
                } else if (val1[i] !== val2[i]) {
                    return false
                }
            }
        }
        // Direct comparison for primitive values
        else if (val1 !== val2) {
            return false
        }
    }

    // If we've made it this far, the dictionaries are equal
    return true
}
