import { toastErrorTypes } from 'components/toast/toast'
import { useApi } from 'context/api-context'
import useToastContext from 'context/toast-context'
import { PAYMENT_PROCESSOR } from 'enum/payment-processor'
import { PRODUCT_TYPE } from 'enum/product-type'
import { IAddress } from 'interfaces/address'
import { IOnboardingFlowLineItemRequest } from 'interfaces/onboarding-flow'
import { ILineItemPrescription, ILineItemStripe, IOrder } from 'interfaces/order/order'
import { IProductMembership } from 'interfaces/product/product'
import * as React from 'react'

interface IRefillOrderBody {
    customer_email: string
    requested_prescriptions: {
        prescription_id: string
        quantity: number
        acknowledge_allow_stack_fills: boolean
    }[]
    // for now we assume this is true and only
    // allow user to click on the checkout button if consented
    // else we'd have to generate an orderDetails everytime these consents change
    acknowledge_not_crc_agreement: true
    authorization_for_use_and_disclosure_agreement: true
    acknowledge_allow_stack_fills: boolean
}

export interface IOnboardingOrderBody {
    requested_prescriptions: {
        drug: string
        requested_quantity: number
        day_supply: number | undefined
        pills_per_day: number | undefined
        total_pills_direct_input: number | undefined
        first_name: string
        last_name: string
        date_of_birth: string
        email: string
    }[]
    allergies: string[]
    line_items: (IOnboardingFlowLineItemRequest | IProductMembership)[]
    sex_assigned_at_birth: string
    email: string
}

export interface IReturn extends IOrder {
    createOrder: (details: IOnboardingOrderBody | IRefillOrderBody) => void
    updateOrderShippingAddress: (orderId: string, shippingAddress: IAddress) => void
    totalPrice: number | undefined
    subTotalPrice: number | undefined
    shippingPriceAfterDiscount: number | undefined
    isFreeShipping: boolean
    isOrderError: boolean
    isLoading: boolean
    membershipDiscount: number | undefined
    membershipPrice: number
    membershipSaving: number
}

export function useOrder(orderProp?: IOrder): IReturn {
    const api = useApi()
    const { showToast } = useToastContext()

    const [order, setOrder] = React.useState<IOrder>(orderProp)
    const [isOrderError, setIsOrderError] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const createOrder = React.useCallback(
        async (details: IOnboardingOrderBody | IRefillOrderBody) => {
            setIsOrderError(false)
            if (!details.requested_prescriptions.length) {
                setOrder(undefined)
                showToast({
                    type: toastErrorTypes.WARNING,
                    children: `Please add at least one prescription to your order.`,
                })
                return
            }
            setIsLoading(true)

            try {
                const orderData = await api.post('rx/order', {
                    ...details,
                    payment_processor: PAYMENT_PROCESSOR.STRIPE,
                })
                setOrder(orderData)
            } catch (error) {
                console.error(error)
                setIsOrderError(true)
            }
            setIsLoading(false)
        },
        [api, showToast],
    )

    const updateOrderShippingAddress = React.useCallback(
        async (orderId: string, shippingAddress: IAddress) => {
            if (!orderId || !shippingAddress) return
            setIsLoading(true)
            setIsOrderError(false)
            try {
                const orderData = await api.put('rx/order', {
                    order_id: orderId,
                    shipping_address: shippingAddress,
                })
                setOrder(orderData)
            } catch (error) {
                console.error(error)
                setIsOrderError(true)
            }
            setIsLoading(false)
        },
        [api],
    )

    const { line_items, total_price, shipping_price, shipping_discount } = order ?? {}

    const shippingPriceAfterDiscount = Number(shipping_price ?? 0) - Number(shipping_discount ?? 0)

    const isFreeShipping = shippingPriceAfterDiscount === 0

    // group line items based on product/drug id
    // line items can repeat in an order, show the repeated line items
    // as a single line item with the total quantity
    const lineItemsMap = new Map<string, ILineItemStripe>()

    line_items?.forEach((lineItem, index) => {
        // do not merge in case of prescription
        if (lineItem.type === PRODUCT_TYPE.PRESCRIPTION) {
            lineItemsMap.set(String(index), { ...lineItem })
            return
        }

        const key = lineItem.product_id

        if (!lineItemsMap.has(key)) {
            lineItemsMap.set(key, { ...lineItem })
        } else {
            const existingLineItem = lineItemsMap.get(key)
            existingLineItem.quantity += lineItem.quantity
        }
    })

    const { memDis: membershipDiscount, subTotal: subTotalPrice } = line_items?.reduce(
        ({ memDis, subTotal }, lt) => {
            if (lt.type !== PRODUCT_TYPE.PRESCRIPTION) {
                return {
                    memDis,
                    subTotal,
                }
            }

            // todo: explore how types can be setup so TS can infer the type
            lt = lt as ILineItemPrescription

            const discount = Number(lt.standard_total_price) - Number(lt.total_price)

            return {
                memDis: (memDis ?? 0) + discount,
                subTotal: (subTotal ?? 0) + Number(lt.total_price),
            }
        },
        {
            memDis: 0,
            subTotal: 0,
        },
    ) ?? { memDis: undefined, subTotal: undefined }

    const membershipPrice = Number(line_items?.find((lt) => lt.type === PRODUCT_TYPE.MEMBERSHIP)?.total_price)

    return {
        ...order,
        line_items: [...lineItemsMap.values()],
        shippingPriceAfterDiscount,
        totalPrice: typeof total_price !== 'undefined' ? Number(total_price) : total_price,
        subTotalPrice,
        isFreeShipping,
        isOrderError,
        isLoading,
        createOrder,
        updateOrderShippingAddress,
        membershipDiscount,
        membershipPrice,
        membershipSaving: membershipDiscount + Number(shipping_discount),
    }
}
