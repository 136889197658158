import * as React from 'react'
import { IToast } from 'interfaces/toast'
import styles from './style.module.css'

type IProps = IToast

export const toastErrorTypes = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
} as const

export const Toast = ({ children, type }: IProps): React.ReactElement => {
    let typeClasses = ''

    switch (type) {
        case toastErrorTypes.ERROR:
            typeClasses = 'bg-error-medium before:bg-error'
            break
        case toastErrorTypes.SUCCESS:
            typeClasses = 'bg-success-light before:bg-success'
            break
        case toastErrorTypes.WARNING:
            typeClasses = 'bg-warning-light before:bg-warning'
            break
    }

    return (
        <div
            className={`text-sm lg:text-base text-white shadow-sm w-[20rem] rounded-5 before:w-2 overflow-hidden flex ${typeClasses} ${styles['toast']} opacity-0`}
        >
            <div className="px-2 lg:px-3 py-3 lg:py-4">{children}</div>
        </div>
    )
}
