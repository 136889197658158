import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import { rxRefillsConsentFormInternalName } from 'constants/contentful'
import { useRXCartContext } from 'context/rx-cart-context'
import { RxConsent } from 'components/rx-consent'
import { IPageSectionForm } from 'interfaces/page-section-form'

const { globalForms } = globalManifest
const rxConsentsForm = globalForms.find((f) => f.internalName === rxRefillsConsentFormInternalName)

export function CartConsents() {
    const { gotoPaymentStep } = useRXCartContext()

    return (
        <div className="flex flex-col justify-between" data-cy="cart-consents">
            <div className="h-full flex flex-col mt-5">
                <RxConsent
                    form={rxConsentsForm as IPageSectionForm}
                    onAccept={gotoPaymentStep}
                    buttonWrapperClassName="border-t border-gray-medium p-5 bg-white"
                    bodyWrapperClassName="p-5 flex-grow"
                />
            </div>
        </div>
    )
}
