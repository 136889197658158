import { areDictionariesEqual } from '@/lib/util/data-utils'
import { removeSearchParams } from '@/lib/util/url'
import * as Sentry from '@sentry/nextjs'
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeAddressElementOptions, StripePaymentElementOptions } from '@stripe/stripe-js'
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js/dist/stripe-js/elements/address'
import Button from 'components/buttons/button'
import { toastErrorTypes } from 'components/toast/toast'
import { postPaymentFlowUrl } from 'constants/url'
import useToastContext from 'context/toast-context'
import globalManifest from 'data/global-manifest.json'
import { useOrder } from 'hooks/useOrder'
import { IOrder } from 'interfaces/order/order'
import React, { useEffect, useState } from 'react'

export enum CHECKOUT_SOURCE {
    FLOW = 'flow',
    CART = 'cart',
}

interface IStripeAddress {
    line1: string
    line2: string | null
    city: string
    state: string
    postal_code: string
    country: string
}

interface IDefaultAddressSettings {
    name: string
    address?: IStripeAddress
}

interface IProps {
    onSubmitButtonClick?: () => void
    onPaymentFailure?: () => void
    source: CHECKOUT_SOURCE
    order: IOrder
}

const stripeQueryParams = ['payment_intent_client_secret', 'payment_intent', 'redirect_status']

const { resources: globalUIResources } = globalManifest

export function CheckoutForm({ onSubmitButtonClick, source, order }: IProps) {
    const {
        stripe_client_secret,
        customer_name,
        shipping_address,
        order_id,
        total_price,
        updateOrderShippingAddress,
        isLoading: isOrderUpdating,
        isOrderError,
    } = useOrder(order)

    const stripe = useStripe()
    const elements = useElements()
    const { showToast } = useToastContext()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!stripe || !stripe_client_secret) {
            return
        }

        stripe.retrievePaymentIntent(stripe_client_secret).then(({ error }) => {
            if (error) {
                showToast({ type: toastErrorTypes.ERROR, children: `Something went wrong: ${error.message}` })
            }
        })
    }, [stripe, stripe_client_secret, showToast])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url:
                    window.location.origin +
                    postPaymentFlowUrl +
                    '?' +
                    new URLSearchParams(
                        `${removeSearchParams({
                            paramsToRemove: stripeQueryParams,
                            paramString: window.location.search,
                        })}&source=${source}`,
                    ).toString(),
            },
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            if (error.type === 'card_error' || error.type === 'validation_error') {
                showToast({ type: toastErrorTypes.ERROR, children: error.message })
            } else {
                showToast({ type: toastErrorTypes.ERROR, children: 'An unexpected error occurred.' })
            }
            Sentry.captureException(`Stripe payment failed: ${error.message}`)
        }

        setIsLoading(false)
    }

    const defaultValues: IDefaultAddressSettings = { name: customer_name }
    if (shipping_address) {
        defaultValues.address = {
            line1: shipping_address.line_1,
            line2: shipping_address.line_2,
            city: shipping_address.city,
            state: shipping_address.state,
            postal_code: shipping_address.zipcode,
            country: shipping_address.country,
        }
    }

    const addressElementShipping: StripeAddressElementOptions = {
        mode: 'shipping',
        allowedCountries: ['US'],
        autocomplete: {
            mode: 'google_maps_api',
            apiKey: process.env.GOOGLE_MAPS_API_KEY!,
        },
        defaultValues,
    }
    const paymentElementOptions: StripePaymentElementOptions = {
        layout: 'accordion',
    }

    // Update the order on the server side
    const updateOrderAddress = async (evt: StripeAddressElementChangeEvent) => {
        if (!evt.complete) return
        const address = {
            line_1: evt.value.address.line1,
            line_2: evt.value.address.line2,
            city: evt.value.address.city,
            state: evt.value.address.state,
            zipcode: evt.value.address.postal_code,
            country: evt.value.address.country,
        }
        if (areDictionariesEqual(defaultValues.address, evt.value.address)) return
        updateOrderShippingAddress(order_id, address)
    }

    // TODO: Prepopulate payment if we have it for the customer
    return (
        <form id="payment-form" className="grid gap-4" onSubmit={handleSubmit}>
            <div className="bg-white rounded-xl p-4 border border-[#f2f2f2]">
                <AddressElement options={addressElementShipping} onChange={updateOrderAddress} />
            </div>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <Button
                id="submit"
                disabled={isLoading || !stripe || !elements || isOrderUpdating || isOrderError}
                loading={isLoading || isOrderUpdating}
                className="w-full"
                onClick={() => {
                    onSubmitButtonClick?.()
                }}
            >
                {`${globalUIResources['payLabel'].value} - $${total_price}`}
            </Button>
        </form>
    )
}
