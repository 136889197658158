import { getObjectRemoveNils } from '../util/data-utils'
import { fetchBackend } from './backend'
import { IProductResponse } from 'interfaces/product/product'

interface IProductSearch {
    productType?: string
}

const cache = {}

export async function fetchProducts(
    productSearch: IProductSearch,
): Promise<{ data: IProductResponse; error: boolean }> {
    const cachedData = cache[JSON.stringify(productSearch)]
    if (cachedData) {
        return { data: cachedData, error: false }
    }

    const productEndpoint =
        `/api/product/?` + new URLSearchParams(getObjectRemoveNils(productSearch) as Record<string, any>)
    try {
        const resp = await fetchBackend(productEndpoint)

        if (resp.ok) {
            const data = await resp.json()
            cache[JSON.stringify(productSearch)] = data

            return { data, error: false }
        } else {
            return {
                data: undefined,
                error: !resp.ok,
            }
        }
    } catch (e) {
        return {
            data: undefined,
            error: e,
        }
    }
}
