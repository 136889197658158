import * as React from 'react'

interface IProps {
    children?: React.ReactNode
    className?: string
    title?: string | React.ReactNode
    narrow?: boolean // reduces vertical padding
    id?: string
}

/**
 * Wrap distinct sections in pages with this component
 * @param props
 * @returns ReactElement
 */
const Section = (
    { children, className, title, narrow, id }: IProps,
    forwardedRef?: React.ForwardedRef<HTMLElement>,
): React.ReactElement => (
    <section ref={forwardedRef} id={id} className={`section--v2 ${narrow ? 'sm:py-6' : ''} ${className ?? ''}`}>
        {typeof title === 'string' ? <h2 className="section--header mb-8 lg:mb-12 lg:text-center">{title}</h2> : title}
        {children}
    </section>
)

export default React.forwardRef(Section)
