import * as React from 'react'
import { fetchProducts } from '@/lib/api/products'
import { IProductMembership } from 'interfaces/product/product'

export type IFetchMembershipStatus = 'idle' | 'loading' | 'error'
export const membershipPeriodOptions = {
    YEARLY: 'year',
    MONTHLY: 'month',
} as const

export function useMembershipProduct() {
    const [fetchMembershipProductStatus, setFetchMembershipStatus] = React.useState<IFetchMembershipStatus>('idle')
    const [membershipProduct, setMembership] = React.useState<IProductMembership>()

    const fetchMembershipProduct = React.useCallback(async () => {
        setFetchMembershipStatus('loading')
        const { data, error } = await fetchProducts({
            productType: 'MEMBERSHIP',
        })

        if (error) {
            setFetchMembershipStatus('error')
        } else {
            setMembership(data.products[0] as IProductMembership)
            setFetchMembershipStatus('idle')
        }
    }, [])

    return {
        fetchMembershipProduct,
        fetchMembershipProductStatus,
        membershipProduct,
    }
}
