import { fetchBackend } from './backend'
import { getObjectRemoveNils } from '@/lib/util/data-utils'

export interface IUserMembershipQuery {
    email: string
}

const userMembershipStatusCache = {}

export async function fetchUserMembershipStatus(
    userMembershipQuery: IUserMembershipQuery,
): Promise<{ data: { has_membership: boolean }; error: boolean }> {
    const cachedData = userMembershipStatusCache[JSON.stringify(userMembershipQuery)]

    if (cachedData) {
        return { data: cachedData, error: false }
    }

    const userMembershipEndpoint =
        `/api/customer/membership-check/?` +
        new URLSearchParams(getObjectRemoveNils(userMembershipQuery) as Record<string, any>)

    try {
        const resp = await fetchBackend(userMembershipEndpoint)
        if (resp.ok) {
            const data = await resp.json()
            userMembershipStatusCache[JSON.stringify(userMembershipQuery)] = data
            return { data, error: false }
        } else {
            return {
                data: undefined,
                error: !resp.ok,
            }
        }
    } catch (error) {
        return {
            data: undefined,
            error,
        }
    }
}

interface IMembershipPortalResponse {
    data: {
        portal_url: string | null
    }
    error?: boolean
}
export async function fetchUserMembershipPortal(idToken: string): Promise<IMembershipPortalResponse> {
    try {
        const resp = await fetchBackend('/api/customer/portal', {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-type': 'application/json',
            },
        })
        if (resp.ok) {
            const data = await resp.json()
            return { data, error: false }
        } else {
            return {
                data: undefined,
                error: !resp.ok,
            }
        }
    } catch (error) {
        return {
            data: undefined,
            error,
        }
    }
}
