import { getCookieValue } from '../util/cookie'
import { fetchWithTimeout } from '../util/fetch'

async function getCSRFToken() {
    const response = await fetchBackend('/api/csrf/', {
        method: 'GET',
    })
    return response.ok
}

/**
 * Same as native fetch except this prefixes all endpoints
 * with the backend host and handles CSRF tokens
 * @param endpoint Must begin with '/'
 */
export async function fetchBackend(endpoint: string, init?: RequestInit): Promise<Response> {
    const requestOptions: RequestInit = {
        credentials: 'include', // Always include credentials
        ...init,
    }

    // If it's a POST/PUT/PATCH/DELETE request and doesn't have X-CSRFToken header,
    // add it from cookie
    if (
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(requestOptions.method || '') &&
        !requestOptions.headers?.['X-CSRFToken']
    ) {
        // Ensure we have a CSRF token
        await getCSRFToken()

        requestOptions.headers = {
            ...requestOptions.headers,
            'X-CSRFToken': getCookieValue('csrftoken') || '',
        }
    }

    // Node replaces localhost with an IPv6 address. We need the IPv4 address for the URL to work
    const url = (process.env.BACKEND_ENDPOINT || '').replace('localhost', '127.0.0.1') + endpoint
    return fetchWithTimeout(url, requestOptions, 15000)
}
