import { useQuery, useMutation } from '@tanstack/react-query'
import { useApi } from 'context/api-context'

export function useApiGet<TData = unknown>(
    apiUrl: string,
    {
        params,
        enabled,
    }: {
        params?: Record<string, any>
        enabled?: boolean
    } = { enabled: true },
) {
    const api = useApi()
    const queryKey: any[] = [apiUrl]
    if (params) {
        queryKey.push(params)
    }
    return useQuery<TData>({
        queryKey,
        queryFn: async () => {
            return (await api.get(apiUrl, { params })) as TData
        },
        enabled,
    })
}

type MutationMethods = 'post' | 'put' | 'delete'

function useApiMutation<TData = unknown, TVariables = unknown>(
    apiMethod: MutationMethods,
    apiUrl: string,
    requestData: Record<string, any>,
) {
    const api = useApi()
    return useMutation<TData, TVariables>({
        mutationFn: async () => {
            const { data } = await api[apiMethod](apiUrl, { data: requestData })
            return data
        },
    })
}

export function useApiPost(apiUrl: string, requestData: Record<string, any>) {
    return useApiMutation('post', apiUrl, requestData)
}

export function useApiPut(apiUrl: string, requestData: Record<string, any>) {
    return useApiMutation('put', apiUrl, requestData)
}

export function useApiDelete(apiUrl: string, requestData: Record<string, any>) {
    return useApiMutation('delete', apiUrl, requestData)
}
