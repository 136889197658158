import { CHECKOUT_SOURCE } from 'components/checkout/checkout-form'
import { StripeCheckout } from 'components/checkout/stripe-checkout'
import { IOrder } from 'interfaces/order/order'
import * as React from 'react'

export function CartPayment({ order }: { order: IOrder }) {
    return (
        <div className="p-5" data-cy="cart-payment">
            <StripeCheckout source={CHECKOUT_SOURCE.CART} order={order} />
        </div>
    )
}
