import React from 'react'
import cn from 'classnames'

export interface TableProps {
    children: React.ReactNode
    className?: string
    relaxed?: boolean
}

export const Table: React.FC<TableProps> = ({ children, className = '', relaxed = false }) => {
    const spacing = relaxed ? 'table-relaxed' : 'table-tight'
    return <table className={cn('w-full', spacing, className)}>{children}</table>
}

interface TableHeadProps {
    children: React.ReactNode
    className?: string
}

export const TableHead: React.FC<TableHeadProps> = ({ children, className = '' }) => {
    return <thead className={cn('border-b', className)}>{children}</thead>
}

interface TableBodyProps {
    children: React.ReactNode
    className?: string
}

export const TableBody: React.FC<TableBodyProps> = ({ children, className = '' }) => {
    return <tbody className={className}>{children}</tbody>
}

interface TableRowProps {
    children: React.ReactNode
    className?: string
}

export const TableRow: React.FC<TableRowProps> = ({ children, className = '' }) => {
    return <tr className={cn('border-b', className)}>{children}</tr>
}

export interface TableCellProps {
    children?: React.ReactNode
    className?: string
    isHeader?: boolean
    align?: 'left' | 'center' | 'right'
    verticalAlign?: 'top' | 'middle' | 'bottom'
}

export const TableCell: React.FC<TableCellProps> = ({
    children,
    className = '',
    isHeader = false,
    align = 'left',
    verticalAlign = 'middle',
}) => {
    const Component = isHeader ? 'th' : 'td'
    const alignClass = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    }[align]
    const verticalAlignClass = {
        top: 'align-top',
        middle: 'align-middle',
        bottom: 'align-bottom',
    }[verticalAlign]
    return <Component className={cn('pr-8', alignClass, verticalAlignClass, className)}>{children}</Component>
}
